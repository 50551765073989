import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "performer-industries"
}
const _hoisted_2 = { class: "m-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_a_tag = _resolveComponent("a-tag")!

  return (_ctx.performer?.performerProfile?.industries?.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.performer?.performerProfile?.industries, (tag) => {
          return (_openBlock(), _createBlock(_component_a_tag, {
            key: tag.id,
            class: "performer-industries__tag"
          }, {
            default: _withCtx(() => [
              (_ctx.getIndustryOptionData(tag.name).dropdownIcon)
                ? (_openBlock(), _createBlock(_component_AppIcon, {
                    key: 0,
                    name: _ctx.getIndustryOptionData(tag.name).dropdownIcon,
                    class: "performer-industries__tag__icon"
                  }, null, 8, ["name"]))
                : _createCommentVNode("", true),
              _createElementVNode("span", _hoisted_2, _toDisplayString(tag.name), 1)
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}