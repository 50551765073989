
import { PerformerDetails_performerDetails_PerformerDetails_performerProfile } from "@/api/performer/__generated__/PerformerDetails";
import { getIndustryOptionData } from "@/shared/utils/kycOptions";
import { PropType, defineComponent } from "vue";
import AppIcon from "@/shared/components/Icons/AppIcon.vue";

type Performer = {
  performerProfile:
    | Pick<
        PerformerDetails_performerDetails_PerformerDetails_performerProfile,
        "industries"
      >
    | undefined;
};

export default defineComponent({
  props: {
    performer: Object as PropType<Performer>,
  },
  setup() {},
  methods: { getIndustryOptionData },
  components: { AppIcon },
});
