// Kyc Options
import { i18nTranslate } from "@/plugins/i18n";
import { SelectOptionWithImage } from "@/shared/components/Forms/CheckboxImageInput.vue";
import { KycIconName } from "@/shared/components/Icons/KycIcon.vue";
import { AppIconName } from "@/shared/components/Icons/AppIcon.vue";
import {
  UsersBusinessProfileProductTypeChoices,
  UsersBusinessProfileTargetGenderChoices,
} from "@/../__generated__/globalTypes";
import { DropdownOption } from "@/shared/components/Forms/InputDropdown.vue";

export interface KycSelectOptionWithImage extends SelectOptionWithImage {
  bgIconName: KycIconName;
  fgIconName?: KycIconName;
}

export const businessOptions: KycSelectOptionWithImage[] = [
  {
    bgIconName: "physicalProductsBg",
    fgIconName: "physicalProductsFg",
    label: i18nTranslate("Physical Product"),
    value: UsersBusinessProfileProductTypeChoices.PHYSICAL,
  },
  {
    bgIconName: "digitalProductsBg",
    fgIconName: "digitalProductsFg",
    label: i18nTranslate("Digital Product"),
    value: UsersBusinessProfileProductTypeChoices.DIGITAL,
  },
];

export const industryOptionsDataMap: {
  [key: string]: {
    bgIconName: KycIconName;
    fgIconName?: KycIconName;
    dropdownIcon?: AppIconName;
    label?: string;
  };
} = {
  FASHION: {
    bgIconName: "fashion",
    label: i18nTranslate("Fashion"),
    dropdownIcon: "industryFashion",
  },
  BEAUTY: {
    bgIconName: "beauty",
    label: i18nTranslate("Beauty"),
    dropdownIcon: "industryBeauty",
  },
  FOOD: {
    bgIconName: "food",
    label: i18nTranslate("Food"),
    dropdownIcon: "industryFood",
  },
  LIFESTYLE: {
    bgIconName: "lifestyle",
    label: i18nTranslate("Lifestyle"),
    dropdownIcon: "industryLifestyle",
  },
};

export const getIndustryOptionData = (
  key: string
): {
  bgIconName: KycIconName;
  fgIconName?: KycIconName;
  dropdownIcon?: AppIconName;
  label: string;
} => {
  const data = industryOptionsDataMap[key.toUpperCase()];

  if (data) {
    return {
      bgIconName: data.bgIconName,
      fgIconName: data.fgIconName,
      label: data.label ?? key,
      dropdownIcon: data.dropdownIcon,
    };
  } else {
    // Default Values
    return { label: key, bgIconName: "merchantLogoWhite" };
  }
};

export const productFeelingOptions: KycSelectOptionWithImage[] = [
  {
    bgIconName: "calm",
    label: i18nTranslate("Calm"),
    value: "CALM",
  },
  {
    bgIconName: "confidence",
    label: i18nTranslate("Confidence"),
    value: "CONFIDENCE",
  },
  {
    bgIconName: "happiness",
    label: i18nTranslate("Happiness"),
    value: "HAPPINESS",
  },
  {
    bgIconName: "belongingness",
    label: i18nTranslate("Belongingness"),
    value: "BELONGINGNESS",
  },
  {
    bgIconName: "successful",
    label: i18nTranslate("Successful"),
    value: "SUCCESSFUL",
  },
  {
    bgIconName: "comfort",
    label: i18nTranslate("Comfort"),
    value: "COMFORT",
  },
];

export const productAdjectives = [
  {
    value: "elegant",
    label: i18nTranslate("Elegant"),
  },
  {
    value: "vibrant",
    label: i18nTranslate("Vibrant"),
  },
  {
    value: "simple",
    label: i18nTranslate("Simple"),
  },
  {
    value: "youthful",
    label: i18nTranslate("Youthful"),
  },
  {
    value: "minimalistic",
    label: i18nTranslate("Minimalistic"),
  },
  {
    value: "modern",
    label: i18nTranslate("Modern"),
  },
  {
    value: "classic",
    label: i18nTranslate("Classic"),
  },
  {
    value: "airy",
    label: i18nTranslate("Airy"),
  },
  {
    value: "colorful",
    label: i18nTranslate("Colorful"),
  },
  {
    value: "vintage",
    label: i18nTranslate("Vintage"),
  },
];

export type GenderOption = {
  kycIconName?: string;
  value: string;
  label: string;
};

export const genderOptions: Array<GenderOption> = [
  {
    kycIconName: "genderMale",
    value: UsersBusinessProfileTargetGenderChoices.MALE,
    label: i18nTranslate("Male"),
  },
  {
    kycIconName: "genderFemale",
    value: UsersBusinessProfileTargetGenderChoices.FEMALE,
    label: i18nTranslate("Female"),
  },
  {
    kycIconName: "genderNonBinary",
    value: UsersBusinessProfileTargetGenderChoices.NON_BINARY,
    label: i18nTranslate("Non Binary"),
  },
  {
    value: UsersBusinessProfileTargetGenderChoices.DOES_NOT_MATTER,
    label: i18nTranslate("Does not Matter"),
  },
];

export type AgeOptionKey = 18 | 25 | 35 | 45;

export const ageDropdownOptions: DropdownOption<AgeOptionKey>[] = [
  { label: i18nTranslate("All"), value: null },
  { label: "18-24", value: 18 },
  { label: "25-34", value: 25 },
  { label: "35-44", value: 35 },
  { label: "45+", value: 45 },
];

/**
 * Utility function to get the min and max age range value based on `ageDropdownOptions` value
 * @param key selected `ageDropdownOption` key
 * @returns Always returns a [min, max] number tuple, with both null if key not found.
 */
export const getAgeRangeValue = (
  key: AgeOptionKey | null | undefined
): [number | null, number | null] => {
  switch (key) {
    case 18:
      return [18, 24];
    case 25:
      return [25, 34];
    case 35:
      return [35, 44];
    case 45:
      return [45, null];
    default:
      return [null, null];
  }
};

export const ageSliderOptions: Record<number, string> = {
  18: "18",
  25: "25",
  35: "35",
  45: "45+",
};

export const orderReasonsOptions = [
  {
    label: i18nTranslate(
      "I need videos of my ads (Facebook, Instagram, Tiktok)"
    ),
    value: "ADS",
  },
  {
    label: i18nTranslate("I need videos for my Tiktok"),
    value: "TIKTOK",
  },
  {
    label: i18nTranslate("I need videos for my Instagram"),
    value: "INSTAGRAM",
  },
  {
    label: i18nTranslate("I need videos for my Facebook"),
    value: "FACEBOOK",
  },
  {
    label: i18nTranslate("Other"),
    value: "OTHER",
  },
];
